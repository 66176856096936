import { useState } from "react";
import { FormControl, FormLabel, Input, FormErrorMessage, Button } from "@chakra-ui/react";

function AdminUser() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [cellNr, setCellNr] = useState("");

  const handleUsernameChange = (e) => setUsername(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleCellNrChange = (e) => setCellNr(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement your form submission logic here
  };

  const isError = username === "" || password === "";

  return (
    <form onSubmit={handleSubmit}>
      <FormControl isRequired isInvalid={isError}>
        <FormLabel>Username</FormLabel>
        <Input type="text" value={username} onChange={handleUsernameChange} />
        {username === "" && <FormErrorMessage>Username is required.</FormErrorMessage>}
      </FormControl>

      <FormControl isRequired>
        <FormLabel>Password</FormLabel>
        <Input type="password" value={password} onChange={handlePasswordChange} />
        {password === "" && <FormErrorMessage>Password is required.</FormErrorMessage>}
      </FormControl>

      <FormControl>
        <FormLabel>Email</FormLabel>
        <Input type="email" value={email} onChange={handleEmailChange} />
      </FormControl>

      <FormControl>
        <FormLabel>Cell Nr</FormLabel>
        <Input type="tel" value={cellNr} onChange={handleCellNrChange} />
      </FormControl>

      <Button type="submit">Submit</Button>
    </form>
  );
}

export default AdminUser;
