import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { styled } from "@mui/material/styles";
import { ChakraProvider, Card, CardBody, CardFooter, Button, ButtonGroup, Heading, Image, Stack, Text, Divider } from "@chakra-ui/react";

const ProductCard = ({ product, updateCart }) => {
  const styles = {
    productCard: {
      width: "20%",
      marginBottom: "20px",
      backgroundColor: "#fff",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      padding: "0px 10px 10px 10px",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    productImage: {
      width: "100%",
      height: "200px",
      objectFit: "contain",
      borderRadius: "8px",
      margin: "0px",
    },
    productTitle: {
      fontSize: "18px",
      margin: "0 0 10px 0",
    },
    productDescription: {
      color: "#666",
    },
    productDetails: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%", // Ensure button spans full width
      marginTop: "auto", // Push button to the bottom
    },
    productPrice: {
      fontSize: "16px",
    },
    addToCartBtn: {
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      padding: "8px 12px",
      borderRadius: "4px",
      cursor: "pointer",
    },
    addToCartBtnHover: {
      backgroundColor: "#0056b3",
    },
  };

  const { product_id, product_name, product_description, product_price, product_url } = product;
  const [isAdded, setIsAdded] = useState(false);
  const CustomIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: isAdded ? "#f44336" : "#4caf50",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    padding: "8px",
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: isAdded ? "#d32f2f" : "#43a047",
    },
  }));

  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    const isProductAdded = storedCartItems.includes(product_id);
    setIsAdded(isProductAdded);
  }, [product_id]);

  const toggleCart = () => {
    setIsAdded(!isAdded);
    updateCart(!isAdded, product);
  };

  return (
    <ChakraProvider>
      <Card key={product.id} maxW="sm">
        <CardBody>
          <Image src={product.product_url} alt={product.product_name} borderRadius="lg" />
          <Stack mt="6" spacing="3">
            <Heading size="md">{product.product_name}</Heading>
            <Text>{product.product_description}</Text>
            <Text color="blue.600" fontSize="2xl">
              R{product.product_price}
            </Text>
          </Stack>
        </CardBody>
        <Divider />
        <CardFooter>
        <ButtonGroup spacing='2'>
  <Button
    variant='ghost'
    colorScheme={isAdded ? 'red' : 'blue'}
    onClick={() => {
      toggleCart();
      // Additional functionality for adding to cart if needed
    }}
  >
    {isAdded ? <RemoveIcon /> : <AddIcon />} {isAdded ? "Remove from Cart" : "Add To Cart"}
  </Button>
</ButtonGroup>

        </CardFooter>
      </Card>
    </ChakraProvider>
    //<ChakraProvider>
    // <div style={styles.productCard} className="product-card">
    //   <img src={product_url} alt={product_name} style={styles.productImage} className="product-image" />
    //   <h3 style={styles.productTitle} className="product-title">
    //     {product_name}
    //   </h3>
    //   <p style={styles.productDescription} className="product-description">
    //     {product_description}
    //   </p>
    //   <div style={styles.productDetails} className="product-details">
    //     <span style={styles.productPrice} className="product-price">
    //       ${product_price}
    //     </span>
    //     <CustomIconButton onClick={toggleCart}>
    //       {isAdded ? <RemoveIcon /> : <AddIcon />}
    //     </CustomIconButton>
    //   </div>
    // </div>
    //</ChakraProvider>
  );
};

export default ProductCard;
