import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

function Login({ handleLogin }) {
  const styles = {
    containerTheme: {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      padding: "25px",
      margin: "40px auto",
      display: "flex",
      justifyContent: "space-around",
      listStyle: "none",
      borderRadius: "8px",
      backdropFilter: "blur(10px)",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.3)",
      maxWidth:'40%'
    },
    formTheme: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    buttonTheme: {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      color: "#000",
      backdropFilter: "blur(10px)",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
      padding: "8px 16px",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      transition: "background-color 0.3s, box-shadow 0.3s",
      width: "100%",
    },
    buttonThemeHover: {
      backgroundColor: "rgba(255, 255, 255, 0.2)",
      boxShadow: "0 6px 8px rgba(0, 0, 0, 0.3)",
    },
    inputTheme: {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      color: "#000",
      border: "none",
      borderRadius: "4px",
      padding: "8px 12px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
      transition: "background-color 0.3s, box-shadow 0.3s",
      marginBottom:'22px',
      width: "100%", // Ensure input takes full width
    },
    inputThemeFocus: {
      outline: "none",
      backgroundColor: "rgba(255, 255, 255, 0.2)",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.3)",
    },
    labeltheme: {
      color: "rgba(0, 0, 0, 0.7)", // Neumorphic text color
      fontSize: "18px", // Neumorphic font size
      marginBottom: "8px", // Spacing between label and input
    },
    label: {
      color: "rgba(0, 0, 0, 0.7)", // Neumorphic text color
      fontSize: "18px", // Neumorphic font size
      marginBottom: "8px", // Spacing between label and input
    },
  };

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
   await  handleLogin(username, password);
   navigate('/');
  };

  return (
    <div style={styles.containerTheme}>
      <form onSubmit={handleSubmit} style={styles.formTheme}>

        <label htmlFor="username" style={styles.label}>Username</label>
        <input type="text" id="username" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" style={styles.inputTheme} />

        <label htmlFor="password" style={styles.label}>Password</label>
        <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" style={styles.inputTheme} />
       
        <button type="submit" style={styles.buttonTheme}>
          Login
        </button>
      </form>
    </div>
  );
}

export default Login;
