import { useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Box,
} from '@chakra-ui/react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'; 
import axios from 'axios';

import { storage } from '../../../../components/firebase';

function EditProduct() {
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleProductNameChange = (e) => setProductName(e.target.value);
  const handleProductDescriptionChange = (e) => setProductDescription(e.target.value);
  const handleProductPriceChange = (e) => setProductPrice(e.target.value);
  const handleImageUpload = (e) => setImageFile(e.target.files[0]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Upload image to Firebase Storage
    const storageRef = ref(storage, 'product-list/' +imageFile.name);
    await uploadBytes(storageRef, imageFile);

    // // Get image download URL
    const downloadUrl = await getDownloadURL(storageRef);
    setImageUrl(downloadUrl);

    const retailer_uuid = localStorage.getItem("retailer_uuid");
    const user_id = localStorage.getItem("user_id");
    const formData = {
      retailer_id:retailer_uuid,
      product_name:productName,
      product_description:productDescription,
      product_price:productPrice,
      product_url: downloadUrl,
    };

    //// Send form data to backend
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/product/insert-product`, formData);
      const { data, message, status } = response.data;

      //response message
      // setShowToast(true);
      // setToast_message(message);
      // setToast_success(status);
      console.log({data}); // Handle response from backend
    } catch (error) {
      console.error('Error:', error);
    }

    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl isRequired>
        <FormLabel>Product Name</FormLabel>
        <Input
          type='text'
          value={productName}
          onChange={handleProductNameChange}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Product Description</FormLabel>
        <Textarea
          value={productDescription}
          onChange={handleProductDescriptionChange}
        />
      </FormControl>

      <FormControl isRequired>
        <FormLabel>Product Price</FormLabel>
        <Input
          type='number'
          value={productPrice}
          onChange={handleProductPriceChange}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Upload Image</FormLabel>
        <Input
          type='file'
          accept='image/*'
          onChange={handleImageUpload}
        />
      </FormControl>

      <Box mt={4}>
        <Button type='submit' colorScheme='blue' isLoading={isSubmitting}>
          Submit
        </Button>
      </Box>
    </form>
  );
}

export default EditProduct;
