import { useState } from "react";
import { FormControl, FormLabel, Input, FormHelperText, FormErrorMessage, Button } from "@chakra-ui/react";

function AddClient() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [cellNr, setCellNr] = useState("");
  const [receiver, setReceiver] = useState("");

  const handleUsernameChange = (e) => setUsername(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleAddressChange = (e) => setAddress(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleCellNrChange = (e) => setCellNr(e.target.value);
  const handleReceiverChange = (e) => setReceiver(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement your form submission logic here
  };

  const isError = username === "" || password === "" || receiver === "";

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <FormControl isRequired isInvalid={isError}>
        <FormLabel>Username</FormLabel>
        <Input type="text" value={username} onChange={handleUsernameChange} autoComplete="new-username"/>
        {username === "" && <FormErrorMessage>Username is required.</FormErrorMessage>}
      </FormControl>

      <FormControl isRequired>
        <FormLabel>Password</FormLabel>
        <Input type="password" value={password} onChange={handlePasswordChange} autoComplete="new-password"/>
      </FormControl>

      <FormControl>
        <FormLabel>Address</FormLabel>
        <Input type="text" value={address} onChange={handleAddressChange} />
      </FormControl>

      <FormControl>
        <FormLabel>Email</FormLabel>
        <Input type="email" value={email} onChange={handleEmailChange} />
      </FormControl>

      <FormControl>
        <FormLabel>Cell Nr</FormLabel>
        <Input type="tel" value={cellNr} onChange={handleCellNrChange} />
      </FormControl>

      <FormControl isRequired>
        <FormLabel>Receiver</FormLabel>
        <Input type="text" value={receiver} onChange={handleReceiverChange} />
        {receiver === "" && <FormErrorMessage>Receiver is required.</FormErrorMessage>}
      </FormControl>

      <Button type="submit">Submit</Button>
    </form>
  );
}

export default AddClient;
