import React, { useState, useEffect, useContext } from "react";
import ProductCard from "./components/ProductCard";
//import { TextField, InputAdornment, IconButton } from "@mui/material";
//import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import CartContext from "../../components/CartContext";
import { ChakraProvider, SimpleGrid, Input, InputGroup, InputLeftElement, IconButton } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

function Home() {
  const styles = {
    productListContainer: {
      maxWidth: "1200px",
      margin: "0 auto",
      padding: "20px",
    },
    productList: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      gap: 25,
    },
    searchInput: {
      width: "100%",
      marginBottom: "20px",
    },
  };
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { addToCart, removeFromCart } = useContext(CartContext);
  //fetch productlist
  useEffect(() => {
    const fetchData = async () => {
      try {
        const retailer_uuid = localStorage.getItem("retailer_uuid");
        const response = await axios.post(`${process.env.REACT_APP_URL}/home/get-product-list`, {
          retailer_id: retailer_uuid,
        });
        setProducts(response.data.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchData();
  }, []);
  //filter product-list
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  const filteredProducts = products.filter((product) => product.product_name.toLowerCase().includes(searchTerm.toLowerCase()));
  const updateCart = async (included, product) => {
    try {
      const retailer_uuid = localStorage.getItem("retailer_uuid");
      const user_id = localStorage.getItem("user_id");
      const product_id = product.product_id;
      included ? addToCart() : removeFromCart();

      const storedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      if (included) {
        localStorage.setItem("cartItems", JSON.stringify([...storedCartItems, product_id]));
      } else {
        const updatedCartItems = storedCartItems.filter((item) => item !== product_id);
        localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
      }
      const response = await axios.post(`${process.env.REACT_APP_URL}/home/update-cart`, {
        user_id,
        product,
        included,
      });
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  return (
    <ChakraProvider>
      <InputGroup>
        <InputLeftElement >
          <IconButton aria-label="Search" icon={<SearchIcon />} onClick={handleSearch} />
        </InputLeftElement>
        <Input variant="outline" placeholder="Search..." onChange={(e) => handleSearch(e)} w="100%"  />
      </InputGroup>
      <SimpleGrid columns={4} spacing={1}>
        {filteredProducts.map((product) => (
          <ProductCard key={product.product_id} product={product} updateCart={updateCart} />
        ))}
      </SimpleGrid>
      {/* <div style={styles.productListContainer}>
      <TextField
        style={styles.searchInput}
        variant="outlined"
        placeholder="Search..."
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <div style={styles.productList}>
        {filteredProducts.map((product) => (
          <ProductCard key={product.product_id} product={product} updateCart={updateCart} />
        ))}
      </div>
    </div> */}
    </ChakraProvider>
  );
}
export default Home;
