import { useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Button,
  Box,
} from '@chakra-ui/react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'; 
import axios from 'axios';

import { storage } from '../../../../components/firebase';

function AddProduct() {
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [selectedBrands, setSelectedBrands] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const categories = ['150g Toffee', '30g Toffee Bars', '200g Toffee Spread','150g Soft Caramels','45g Soft Caramels','Gift Boxes','Gift Hampers'];
  const [isSubmitting, setIsSubmitting] = useState(false);


  const handleProductNameChange = (e) => setProductName(e.target.value);
  const handleProductDescriptionChange = (e) => setProductDescription(e.target.value);
  const handleProductPriceChange = (e) => setProductPrice(e.target.value);
  const handleImageUpload = (e) => setImageFile(e.target.files[0]);
  const handleBrandChange = (e) => setSelectedBrands(e.target.value);
  const handleCategoryToggle = (category) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(selectedCategories.filter((c) => c !== category));
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Upload image to Firebase Storage
    const storageRef = ref(storage, 'product-list/' +imageFile.name);
    await uploadBytes(storageRef, imageFile);

    // // Get image download URL
    const downloadUrl = await getDownloadURL(storageRef);
    setImageUrl(downloadUrl);

    const retailer_uuid = localStorage.getItem("retailer_uuid");
    const user_id = localStorage.getItem("user_id");
    const formData = {
      retailer_id:retailer_uuid,
      product_name:productName,
      product_description:productDescription,
      product_price:productPrice,
      product_url: downloadUrl,
    };

    //// Send form data to backend
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/product/insert-product`, formData);
      const { data, message, status } = response.data;

      //response message
      // setShowToast(true);
      // setToast_message(message);
      // setToast_success(status);
      console.log({data}); // Handle response from backend
    } catch (error) {
      console.error('Error:', error);
    }

    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl isRequired>
        <FormLabel>Product Name</FormLabel>
        <Input
          type='text'
          value={productName}
          onChange={handleProductNameChange}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Product Description</FormLabel>
        <Textarea
          value={productDescription}
          onChange={handleProductDescriptionChange}
        />
      </FormControl>

      <FormControl isRequired>
        <FormLabel>Product Price</FormLabel>
        <Input
          type='number'
          value={productPrice}
          onChange={handleProductPriceChange}
        />
      </FormControl>

      <FormControl>
      <FormLabel>Brand</FormLabel>
      <Select placeholder='Select Brand' value={selectedBrands} onChange={handleBrandChange}>
        <option value='option1'>Option 1</option>
        <option value='option2'>Option 2</option>
        <option value='option3'>Option 3</option>
      </Select>
      </FormControl>

      <FormControl mt="2">
        <FormLabel>Categories</FormLabel>
        <Box>
          {categories.map((category) => (
            <Button
              key={category}
              variant={selectedCategories.includes(category) ? 'solid' : 'outline'}
              colorScheme={selectedCategories.includes(category) ? 'green' : 'grey'}
              mr={2}
              mb={2}
              onClick={() => handleCategoryToggle(category)}
            >
              {category}
            </Button>
          ))}
        </Box>
      </FormControl>

      <FormControl mt="4">
        <FormLabel>Upload Image</FormLabel>
        <Input
          type='file'
          accept='image/*'
          onChange={handleImageUpload}
        />
      </FormControl>



      <Box mt={4}>
        <Button type='submit' colorScheme='blue' isLoading={isSubmitting}>
          Submit
        </Button>
      </Box>
    </form>
  );
}

export default AddProduct;
