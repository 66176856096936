import React,{useState} from "react";
import {Box,Flex, Text, Button,Card,Badge,Icon   } from "@chakra-ui/react";
import {CalendarIcon,ArrowForwardIcon} from '@chakra-ui/icons';
function ClientOrder({ order }) {
  const [order_status, setOrder_status] = useState(order.order_status_name);
  const [deliveryDate, setDeliveryDate] = useState(order.delivery_date||"");
  const [payment_status, setPayment_status] = useState(order.payment_status_name);

  return (
    <Flex alignItems="center">
      {/* First Column */}
      <Box w="10%" textAlign="center">
        <Icon boxSize={6} as={CalendarIcon} />
      </Box>

      {/* Second Column */}
      <Box w="65%">
        <Text fontSize="lg" fontWeight="bold">Order No. {order.order_id}</Text>
        <Text fontSize="md">{order.created_at}</Text>
        <Flex alignItems="center">
          <Text fontWeight="600">Status</Text>
          <Badge ml='1'>{order_status}</Badge>
        </Flex>
        <Text fontSize="md">Delivered on: {deliveryDate||'TBC'}</Text>
      </Box>

      {/* Third Column */}
      <Flex w="25%" justifyContent="space-between">
        {/* First Subcolumn */}
        <Box>
          <Text>{order.total_quantity} items</Text>
          <Text fontWeight="600">R{order.total_amount}</Text>
        </Box>
        
        {/* Second Subcolumn */}
        <Icon boxSize={6} as={ArrowForwardIcon} />
      </Flex>
    </Flex>
  );

}

export default ClientOrder;
