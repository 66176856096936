import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage';


const firebaseConfig = {
  apiKey: "AIzaSyCCciZcf5uaSZp-WowIyQA4FajGqJu1g28",
  authDomain: "ordering-db-db2b2.firebaseapp.com",
  projectId: "ordering-db-db2b2",
  storageBucket: "ordering-db-db2b2.appspot.com",
  messagingSenderId: "248477134650",
  appId: "1:248477134650:web:daf6b0807bceffb8db2a03",
  measurementId: "G-M88VR58V7T"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app); // Initialize Firebase Storage

export { app, analytics, storage }; 