import React, { useState, useEffect,useContext } from "react";
import CartItem from "./components/CartItem";
import CartSummary from "./components/CartSummary";
import axios from "axios";
import Toast from "../.././components/Toast";
import CartContext from '../../components/CartContext';
import { ChakraProvider,Box, Flex } from "@chakra-ui/react";


function Cart() {
  const [cartItems, setCartItems] = useState([]);
  const [cart_id, setCart_id] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [toast_success, setToast_success] = useState(false);
  const [toast_message, setToast_message] = useState("");
  const [showToast, setShowToast] = useState(false);

  const { resetCart } = useContext(CartContext);
  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const user_id = localStorage.getItem("user_id");
        const response = await axios.post(`${process.env.REACT_APP_URL}/cart/get-cart`, {
          user_id,
        });
        const { data, message, status } = response.data;
        setCart_id(data.cart_id);
        setCartItems(data.cart_items);
        setShowToast(true);
        setToast_message(message);
        setToast_success(status);
      } catch (error) {
        console.error("Error fetching cart items:", error);
      }
    };
    fetchCartItems();
  }, []);

  const updateCartItem = async (id, quantity, product_price) => {
    const updatedItems = cartItems.map((item) => {
      if (item.cart_items_id === id) {
        const updatedQuantity = parseInt(quantity);
        const updatedAmount = updatedQuantity * parseFloat(item.initial_amount);
        return { ...item, quantity: updatedQuantity, amount: updatedAmount };
      } else {
        return item;
      }
    });
    setCartItems(updatedItems);
    await axios.post(`${process.env.REACT_APP_URL}/cart/update-cart-item`, {
      cart_items_id: id,
      quantity,
      product_price,
    });
  };

  const submitCart = async (totalQuantity, totalPrice) => {
    setShowToast(false);
    const user_id = localStorage.getItem("user_id");
    const response = await axios.post(`${process.env.REACT_APP_URL}/cart/update-cart`, {
      cart_id,
      totalQuantity,
      totalPrice,
      user_id,
      payment_type:paymentMethod
    });
    const { data, message, status } = response.data;

    //reponse message
    setShowToast(true);
    setToast_message(message);
    setToast_success(status);

    //remove added items from home.js
    localStorage.removeItem('cartItems'); 
    //reset nabar cart count
    resetCart();
  };
  const handlePaymentMethod = async (selectedPaymentMethod) => {setPaymentMethod(selectedPaymentMethod);};

  const totalQuantity = cartItems.reduce((total, item) => total + parseInt(item.quantity), 0);
  const totalPrice = cartItems.reduce((total, item) => total + parseFloat(item.product_price) * parseInt(item.quantity), 0);

  return (
    <ChakraProvider>
    <Flex flexDirection="row">
      {showToast && <Toast message={toast_message} isSuccess={toast_success} />}
      <Flex flex={2} flexDirection="column">
        {cartItems.map((item) => (
          <CartItem key={item.cart_items_id} item={item} updateCartItem={updateCartItem} />
        ))}
      </Flex>
      <Flex flex={1}>
        <CartSummary totalQuantity={totalQuantity} totalPrice={totalPrice} submitCart={submitCart} handlePaymentMethod={handlePaymentMethod} />
      </Flex>
    </Flex>
    </ChakraProvider>
    // <div style={{ display: "flex", flexDirection: "row" }}>
    //   {showToast && <Toast message={toast_message} isSuccess={toast_success} />}
    //   <div style={{ flex: 2 }}>
    //       {cartItems.map((item) => (
    //         <CartItem key={item.cart_items_id} item={item} updateCartItem={updateCartItem} />
    //       ))}
    //     </div>
    //     <div style={{ flex: 1 }}>
    //       <CartSummary totalQuantity={totalQuantity} totalPrice={totalPrice} submitCart={submitCart} handlePaymentMethod={handlePaymentMethod} />
    //     </div>
    // </div>
  );
}
export default Cart;
