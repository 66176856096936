import axios from "axios";
import React, { useEffect, useState } from "react"; //useContext,useState
import Toast from "../.././components/Toast";
import ClientOrder from "./components/ClientOrder";
import AdminOrder from "./components/AdminOrder";
import {ChakraProvider, Box,Flex, Text, Button,Card,Badge,Icon   } from "@chakra-ui/react";
import { Stack } from "@chakra-ui/react";
function Orders() {
  const [orders, setOrders] = useState([]);
  const [toast_success, setToast_success] = useState(false);
  const [toast_message, setToast_message] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [isClient, setIsClient] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    //admin or client
    const { client_user, retailer_user } = JSON.parse(localStorage.getItem("user_type"));
    setIsClient(client_user);
    setIsAdmin(retailer_user);

    const fetchCartItems = async () => {
      try {
        const user_id = localStorage.getItem("user_id");
        const retailer_uuid=localStorage.getItem('retailer_uuid');
        const response = await axios.post(`${process.env.REACT_APP_URL}/orders/get-orders`, {
          user_id,
          retailer_uuid,
          client_user,
          retailer_user
        });
        const { data, message, status } = response.data;

        //response message
        setShowToast(true);
        setToast_message(message);
        setToast_success(status);
        //set data
        setOrders(data.orders);
      } catch (error) {
        console.error("Error fetching cart items:", error);
      }
    };
    fetchCartItems();
  }, []);

  return (
    <ChakraProvider>
      {showToast && <Toast message={toast_message} isSuccess={toast_success} />}
      <h1>Orders</h1>
      <Stack spacing={4} width="50%">
      {isClient &&(
        orders.map((order) => (
          <ClientOrder key={order.order_id} order={order} />
        ))
      )}
      {isAdmin &&(
        orders.map((order) => (
          <AdminOrder key={order.order_id} order={order} />
        ))
      )}
    </Stack>
    </ChakraProvider>
  );
}

export default Orders;
