import React from 'react';
//amount,cart_id,cart_items_id,product_description,product_id,product_name,product_price,product_url,quantity
import { Box, Button, Flex, Image, Select, Stack, Text, Heading } from "@chakra-ui/react";

function CartItem({ item, updateCartItem }) {
  return (
    <Flex direction={{ base: 'column', sm: 'row' }} overflow='hidden' variant='outline' borderWidth="1px" borderRadius="md" padding="4">
      <Image
        objectFit='cover'
        maxW={{ base: '200px', sm: '200px' }} // Adjust as needed
        h={{ base: '200px', sm: 'auto' }} // Adjust as needed
        src={item.product_url}
        alt={item.product_name}
      />

      <Stack flex="1" marginLeft={{ base: '0', sm: '4' }} marginTop={{ base: '4', sm: '0' }}>
        <Box>
          <Heading size='md'>{item.product_name}</Heading>
          <Text py='2'>{item.product_description}</Text>
        </Box>

        <Box>
          <Flex justifyContent="flex-end">
            <Text>Price: R{parseFloat(item.product_price).toFixed(2)}</Text>
          </Flex>
          <Flex justifyContent="flex-end" marginTop="10px">
            <Select onChange={(e) => updateCartItem(item.cart_items_id, e.target.value, item.product_price)} value={item.quantity} minWidth="100px">
              {[...Array(10).keys()].map(num => (
                <option key={num} value={num + 1}>{num + 1}</option>
              ))}
            </Select>
          </Flex>
        </Box>
      </Stack>
    </Flex>
  );
}



// function CartItem({ item, updateCartItem }) {
//   return (
//    <div style={{ display: 'flex', border: '1px solid #ccc', margin: '10px', padding: '10px' }}>
//         {/* First Column for Image */}
//         <div style={{ flex: 2, marginRight: '10px' }}>
//           <img src={item.product_url} alt={item.product_name} style={{ width: '100%', height: '100%' }} />
//         </div>
  
//         {/* Second Column for Name, Description, and Regions */}
//         <div style={{ flex: 2, marginRight: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//           <h3>{item.product_name}</h3>
//           <p>{item.product_description}</p>
//           {/* <p>In stock regions: {item.regions.join(', ')}</p> */}
//         </div>
  
//         {/* Third Column for Price and Quantity */}
//         <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
//           <p>Price: R{parseFloat(item.product_price).toFixed(2)}</p>
//           <select onChange={(e) => updateCartItem(item.cart_items_id, e.target.value,item.product_price)} value={item.quantity}>
//             {[...Array(10).keys()].map(num => (
//               <option key={num} value={num + 1}>{num + 1}</option>
//             ))}
//           </select>
//         </div>
//       </div>
//     );
//   }

export default CartItem;