import React, { useState } from "react";
import { ChakraProvider, Button, Select,Box,
  Modal, ModalOverlay, ModalContent, ModalHeader,ModalCloseButton, ModalBody, ModalFooter, Center,
  List, ListItem, ListIcon, Icon  } from "@chakra-ui/react";
import { AddIcon, EditIcon, SmallAddIcon } from "@chakra-ui/icons";

import AddClient from "./components/users/AddClient";
import EditProfile from "./components/profile/EditProfile";
import AddProduct from "./components/products/AddProduct";
import EditProduct from "./components/products/EditProduct";

function Profile() {
  const [selectedProfileForm, setSelectedProfileForm] = useState(null);
  const [selectedUserForm, setSelectedUserForm] = useState(null);
  const [selectedProductForm, setSelectedProductForm] = useState(null);

  const [isProfileHovered, setIsProfileHovered] = useState(false);
  const [isUserHovered, setIsUserHovered] = useState(false);
  const [isProductHovered, setIsProductHovered] = useState(false);

  const handleSelectProfileForm = (form) => {
    setSelectedProfileForm(form);
  };

  const handleSelectUserForm = (form) => {
    setSelectedUserForm(form);
  };

  const handleSelectProductForm = (form) => {
    setSelectedProductForm(form);
  };

  return (
    <ChakraProvider>
      <Box display="flex" ml="2">
        {/* Column 1 */}
        <Box width="400px">
          <Box>
            <Button
              onMouseEnter={() => setIsProfileHovered(true)}
              onMouseLeave={() => setIsProfileHovered(false)}
              size="lg"
              width="200px"
            >
              Profile
            </Button>
            {isProfileHovered && (
              <List onMouseEnter={() => setIsProfileHovered(true)} onMouseLeave={() => setIsProfileHovered(false)}>
                <ListItem onClick={() => handleSelectProfileForm("edit")} cursor="pointer" textAlign="left">
                  <Button width="50%" variant="outline" borderColor="blue.500" borderRadius="md">
                    <ListIcon as={EditIcon} color="blue.500" />
                    Edit Profile
                  </Button>
                </ListItem>
              </List>
            )}
          </Box>
        </Box>

        {/* Column 2 */}
        <Box mx={4} width="400px">
          <Box>
            <Button
              onMouseEnter={() => setIsUserHovered(true)}
              onMouseLeave={() => setIsUserHovered(false)}
              size="lg"
              width="200px"
            >
              Users
            </Button>
            {isUserHovered && (
              <List onMouseEnter={() => setIsUserHovered(true)} onMouseLeave={() => setIsUserHovered(false)}>
                <ListItem onClick={() => handleSelectUserForm("add")} cursor="pointer" textAlign="left">
                  <Button width="50%" variant="outline" borderColor="blue.500" borderRadius="md">
                    <ListIcon as={AddIcon} color="blue.500" />
                    Add Customer
                  </Button>
                </ListItem>
              </List>
            )}
          </Box>
        </Box>

        {/* Column 3 */}
        <Box width="400px">
          <Box>
            <Button
              onMouseEnter={() => setIsProductHovered(true)}
              onMouseLeave={() => setIsProductHovered(false)}
              size="lg"
              width="200px"
            >
              Products
            </Button>
            {isProductHovered && (
              <List onMouseEnter={() => setIsProductHovered(true)} onMouseLeave={() => setIsProductHovered(false)}>
                <ListItem onClick={() => handleSelectProductForm("add")} cursor="pointer" textAlign="left">
                  <Button width="50%" variant="outline" borderColor="blue.500" borderRadius="md">
                    <ListIcon as={AddIcon} color="blue.500" />
                    Add Product
                  </Button>
                </ListItem>
                <ListItem onClick={() => handleSelectProductForm("edit")} cursor="pointer" textAlign="left">
                  <Button width="50%" variant="outline" borderColor="blue.500" borderRadius="md">
                    <ListIcon as={EditIcon} color="blue.500" />
                    Edit Product
                  </Button>
                </ListItem>
              </List>
            )}
          </Box>
        </Box>
      </Box>

      {/* Modals */}
      <Modal isOpen={selectedProfileForm !== null} onClose={() => setSelectedProfileForm(null)} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Profile</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {selectedProfileForm === "edit" && <EditProfile />}
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={selectedUserForm !== null} onClose={() => setSelectedUserForm(null)} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add User</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {selectedUserForm === "add" && <AddClient />}
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={selectedProductForm !== null} onClose={() => setSelectedProductForm(null)} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedProductForm === "add" ? "Add Product" : "Edit Product"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {selectedProductForm === "add" && <AddProduct />}
            {selectedProductForm === "edit" && <EditProduct/>}
          </ModalBody>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );

  // const [selectedForm, setSelectedForm] = useState(null);

  // const handleModalOpen = (formName) => {
  //   setSelectedForm(formName);
  // };

  // const handleModalClose = () => {
  //   setSelectedForm(null);
  // };


  // return (
  //   <ChakraProvider>
  //     <>
  //     <Center>
  //       <Button  leftIcon={<EditIcon />} onClick={() => handleModalOpen("profile")}> Edit Profile </Button>
  //       <Button ml={4} leftIcon={<SmallAddIcon />} onClick={() => handleModalOpen("client")}>Add User</Button>
  //       <Button ml={4} leftIcon={<AddIcon />} onClick={() => handleModalOpen("product")}>Add Product</Button>
  //     </Center>

  //       <Modal isOpen={selectedForm !== null} onClose={handleModalClose}>
  //         <ModalOverlay />
  //         <ModalContent>
  //           <ModalHeader>{selectedForm === "client" ? "Add Client" : selectedForm === "profile" ? "My Profile" : "Add Product"}</ModalHeader>
  //           <ModalCloseButton />
  //           <ModalBody pb={6}>
  //             {selectedForm === "client" && <AddClient />}
  //             {selectedForm === "profile" && <EditProfile />}
  //             {selectedForm === "product" && <AddProduct />}
  //           </ModalBody>

  //           <ModalFooter>
  //             <Button colorScheme="blue" mr={3} onClick={handleModalClose}>
  //               Close
  //             </Button>
  //           </ModalFooter>
  //         </ModalContent>
  //       </Modal>
  //     </>
  //   </ChakraProvider>
  // );
}

export default Profile;
