import React, { createContext, useState } from 'react';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartCount, setCartCount] = useState(0);

  const addToCart = () => {
    setCartCount(prevCount => prevCount + 1);
  };
  const removeFromCart = () => {
    setCartCount(prevCount => Math.max(prevCount - 1, 0)); // Ensure count doesn't go below zero
  };
  const resetCart=()=>{
    setCartCount(0);
  };

  return (
    <CartContext.Provider value={{ cartCount, addToCart, removeFromCart,resetCart }}>
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;