import Home from "./pages/home/Home";
import Orders from "./pages/orders/Orders";
import Invoices from "./pages/invoices/Invoices";
import Cart from "./pages/cart/Cart";
import Profile from "./pages/profile/Profile";
import Login from "./pages/login/Login";
import Navbar from "./pages/navbar/Navbar";

import { CartProvider } from './components/CartContext';
import Toast from "./components/Toast";

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes,useLocation } from "react-router-dom"; //Link,
import axios from "axios";
import { app } from './components/firebase'; 

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [showResponseMessage, setShowResponseMessage] = useState(false);

  useEffect(() => {
    const checkItemsExists = () => {
      const retailer_uuid = localStorage.getItem("retailer_uuid");
      const login_type = localStorage.getItem("login_type");
      const user_id = localStorage.getItem("user_id");
      if (retailer_uuid !== null && login_type !== null && user_id !== null) {
        setIsLoggedIn(true);
        setResponseMessage("Login Successful!");
        setShowResponseMessage(true);
      }
    };
    checkItemsExists();
  }, []);

  const handleLogin = async (username, password) => {
    
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/login/authenticate`, {
        username,
        password,
      });

      const { retailer_uuid, status, client_user,retailer_user, user_id, message } = response.data.data;

      localStorage.setItem("retailer_uuid", retailer_uuid);
      localStorage.setItem("user_type", JSON.stringify({ client_user, retailer_user }));
      localStorage.setItem("user_id", user_id);
      setShowResponseMessage(true);
      setResponseMessage(message);
      setIsLoggedIn(status);
 
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  return (
    <div>
    {showResponseMessage && <Toast message={responseMessage} isSuccess={isLoggedIn} />}
    {isLoggedIn ? (
      <AuthenticatedApp setIsLoggedIn={setIsLoggedIn} setShowResponseMessage={setShowResponseMessage} /> 
    ) : (
      <Router> {/* Wrap the Login component with Router */}
        <Login handleLogin={handleLogin} />
      </Router>
    )}
  </div>
  );
}

function AuthenticatedApp({ setIsLoggedIn,setShowResponseMessage }) {   
  const [activeLink, setActiveLink] = useState("/");

  const handleLogout = () => {
    localStorage.removeItem("retailer_uuid");
    localStorage.removeItem("login_type");
    localStorage.removeItem("user_id");
    localStorage.removeItem("cartItems");
    setIsLoggedIn(false);
    setShowResponseMessage(false);

  };
  return (
    <CartProvider>
    <Router>
      <div>
        <Navbar activeLink={activeLink} setActiveLink={setActiveLink} logoutAction={() => handleLogout()} />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/invoices" element={<Invoices />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/profile" element={<Profile />} />
        </Routes>
      </div>
    </Router>
    </CartProvider>
  );
}

export default App;
