import React, { useState, useEffect } from "react";
import { Box, Button, Flex, Select, Heading, Text, Stack, StackDivider,IconButton } from "@chakra-ui/react";
import { ArrowForwardIcon , CheckCircleIcon } from "@chakra-ui/icons"; 

function CartSummary({ totalQuantity, totalPrice, submitCart,handlePaymentMethod }) {

  const [checkoutComplete, setCheckoutComplete] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([
    { value: "0c6620ee-2249-49f6-b406-97ac80941984", label: "COD" },
    { value: "f745f375-02be-4a2e-9f3e-717f60b864e9", label: "EFT" },
  ]);
  const checkout = async () => {
    if(totalQuantity>0){
      setCheckoutComplete(true);
      submitCart(totalQuantity, totalPrice);
    }
  };
  const viewOrders = async () => {
    //load orders page
  };
  function handleDropDown(event) {
    const selectedPaymentMethod = event.target.value;
    handlePaymentMethod(selectedPaymentMethod);
  };
  return (
    <Box padding="20px" borderRadius="8px" boxShadow="lg" 
    border="1px solid #ccc" backgroundColor="white" color="gray.800" 
    fontWeight="bold" margin="10px" width="400px">
      <Heading size='md'>Cart Summary</Heading>
      <Stack divider={<StackDivider />} spacing='4' marginTop="20px">
        <Box>
          <Heading size='xs' textTransform='uppercase'>
            Total Quantity
          </Heading>
          <Text pt='2' fontSize='sm'>
            {totalQuantity}
          </Text>
        </Box>
        <Box>
          <Heading size='xs' textTransform='uppercase'>
            Total Price
          </Heading>
          <Text pt='2' fontSize='sm'>
            R{totalPrice.toFixed(2)}
          </Text>
        </Box>
        {!checkoutComplete && (
         <Flex width="100%">
         <Box width="100%">
           <Heading size='xs' textTransform='uppercase'>
             Payment Method
           </Heading>
           <Select value="" onChange={handleDropDown} marginBottom="15px">
             {dropdownOptions.map((option) => (
               <option key={option.value} value={option.value}>
                 {option.label}
               </option>
             ))}
           </Select>
         </Box>
       </Flex>
        )}
      </Stack>
      {!checkoutComplete && (
        
       <Button
       onClick={() => checkout()} // Add the onClick handler here
       rightIcon={<ArrowForwardIcon  />} // Use the appropriate icon here, either CartIcon or CheckCircleIcon
       colorScheme="teal"
       variant="outline"
       aria-label="Proceed to Checkout" 
       width="100%"
     >
       Proceed to Checkout
     </Button>
      )}
      {checkoutComplete && (
        <Button onClick={() => viewOrders()} marginBottom="10px" borderRadius="0px" border="none" background="linear-gradient(145deg,  #dedede, #ffffff)" boxShadow="lg" padding="8px" cursor="pointer" width="100%">
          Go To Orders
        </Button>
      )}
    </Box>
  );
  // return (
  //   <div style={styles.divSticker}>
  //     <h2>Cart Summary</h2>
  //     <p>Total Quantity: {totalQuantity}</p>
  //     <p>Total Price: R{totalPrice.toFixed(2)}</p>
  //     {!checkoutComplete&&(
  //     <div style={styles.paymentMethod}>
  //       <label htmlFor="paymentMethod" style={styles.label}>Payment Method:</label>
  //       <select id="paymentMethod" style={styles.dropdown} onChange={handleDropDown}>
  //         {dropdownOptions.map((option) => (
  //           <option key={option.value} value={option.value}>
  //             {option.label}
  //           </option>
  //         ))}
  //       </select>
  //     </div>)}
  //     {!checkoutComplete && (
  //       <button style={styles.button} onClick={() => checkout()} disabled={totalQuantity === 0}>
  //         Proceed to Checkout
  //       </button>
  //     )}
  //     {checkoutComplete && (
  //       <button style={styles.button} onClick={() => viewOrders()}>
  //         Go To Orders
  //       </button>
  //     )}
  //   </div>
  // );
}

export default CartSummary;
