import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import BoxIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import InvoiceIcon from "@mui/icons-material/Receipt";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ProfileIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from '@mui/icons-material/Logout';
import CartContext from '../../components/CartContext';
function Navbar({ activeLink, setActiveLink,logoutAction }) {

  const styles = {
    nav: {
      backgroundColor: "rgba(255, 255, 255, 0.1)", // Transparent background with slight opacity
      padding: "10px",
      marginBottom: "20px",
      display: "flex",
      justifyContent: "space-around",
      listStyle: "none",
      borderRadius: "8px",
      backdropFilter: "blur(10px)", // Background blur effect
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.3)", // Box shadow for depth
    },
    link: {
      textDecoration: "none",
      color: "#000",
      display: "flex",
      alignItems: "center",
      padding: "10px",
      borderRadius: "8px",
      transition: "background-color 2s ease",
    },
    icon: {
      marginRight: "8px",
      fontSize: "24px",
    },
    selected: {
      backgroundColor: "rgba(255, 255, 255, 0.1)", // Transparent background with slight opacity
      color: "#000",
      backdropFilter: "blur(10px)", // Background blur effect
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)", // Box shadow for depth
    },
    cartCount: {
      position: "relative",
      top: "-8px",
      right: "-8px",
      backgroundColor: "green",
      color: "#fff",
      borderRadius: "50%",
      padding: "4px",
      fontSize: "12px",
      minWidth: "20px",
      textAlign: "center",
    },
  };

  const { cartCount } = useContext(CartContext);

  return (
    <nav style={styles.nav}>
      <Link to="/" style={activeLink === "/" ? { ...styles.link, ...styles.selected } : styles.link} onClick={() => setActiveLink("/")}>
        <HomeIcon style={styles.icon} />
        Home
      </Link>
      <Link to="/orders" style={activeLink === "/orders" ? { ...styles.link, ...styles.selected } : styles.link} onClick={() => setActiveLink("/orders")}>
        <BoxIcon style={styles.icon} />
        Orders
      </Link>
      <Link to="/invoices" style={activeLink === "/invoices" ? { ...styles.link, ...styles.selected } : styles.link} onClick={() => setActiveLink("/invoices")}>
        <InvoiceIcon style={styles.icon} />
        Invoices
      </Link>
      <Link to="/cart" style={activeLink === "/cart" ? { ...styles.link, ...styles.selected } : styles.link} onClick={() => setActiveLink("/cart")}>
        <ShoppingCartIcon style={styles.icon} />
        Cart
        {cartCount > 0 && (
          <span style={styles.cartCount}>{cartCount}</span>
        )}
      </Link>
      <Link to="/profile" style={activeLink === "/profile" ? { ...styles.link, ...styles.selected } : styles.link} onClick={() => setActiveLink("/profile")}>
        <ProfileIcon style={styles.icon} />
        Profile
      </Link>
      <Link to="#" style={styles.link} onClick={logoutAction}>
        <LogoutIcon style={styles.icon} />
      </Link>
    </nav>
  );
}

export default Navbar;
