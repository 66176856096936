import React, { useState, useEffect } from "react";
import { Box, Flex, Text, Select, Badge, Icon, Input, Progress } from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import axios from "axios";
function AdminOrder({ order }) {
  const [order_statusOptions, setOrder_statusOptions] = useState([]);
  const [payment_statusOptions, setPayment_statusOptions] = useState([]);
  const [deliveryDate, setDeliveryDate] = useState(order.delivery_date || "");
  const [order_status, setOrder_status] = useState(order.order_status);
  const [payment_status, setPayment_status] = useState(order.payment_status);
  const [order_status_name, setOrder_status_name] = useState(order.order_status_name);
  const [payment_status_name, setPayment_status_name] = useState(order.payment_status_name);
  const [payment_type_name, setPayment_type_name] = useState(order.payment_type_name);
  const [progress, setProgress] = useState(order.order_progress);

  //get-dropdowns
  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_URL}/orders/get-dropdowns`, {});
        const { data, message, status } = response.data;
        const { dd__order_status, dd_payment_status, dd_payment_type } = data;
        setOrder_statusOptions(dd__order_status);
        setPayment_statusOptions(dd_payment_status);
      } catch (error) {
        console.error("Error fetching cart items:", error);
      }
    };
    fetchCartItems();
  }, []);
  //ups-order_status
  useEffect(() => {
    const update__order_status = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_URL}/orders/ups-order_status`, {
          order_id: order.order_id,
          order_status,
        });
        const { data, message, status } = response.data;
        const { label,order_progress } = data;
        setOrder_status_name(label);
        setProgress(order_progress);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    update__order_status();
  }, [order_status]);
  //ups-payment_status
  useEffect(() => {
    const update__payment_status = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_URL}/orders/ups-payment_status`, {
          order_id: order.order_id,
          payment_status,
        });
        const { data, message, status } = response.data;
        const { label } = data;
        setPayment_status_name(label);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    update__payment_status();
  }, [payment_status]);
  //ups-delivery_date
  useEffect(() => {
    const update__delivery_date = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_URL}/orders/ups-delivery_date`, {
          order_id: order.order_id,
          deliveryDate,
        });
        const { data, message, status } = response.data;
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    update__delivery_date();
  }, [deliveryDate]);

  function handle__order_status(event) {
    const order_status_uuid = event.target.value;
    setOrder_status(order_status_uuid);
  }
  function handle__payment_status(event) {
    const payment_status_uuid = event.target.value;
    setPayment_status(payment_status_uuid);
  }
  function handle__delivery_date(event) {
    setDeliveryDate(event);
  }

  return (
    <Flex alignItems="start" flexDirection="column">
      <Flex alignItems="center">
        {/* First Column */}
        <Box w="10%" textAlign="center">
          <Icon boxSize={6} as={CalendarIcon} />
        </Box>

        {/* Second Column */}
        <Box w="50%">
          <Text fontSize="lg" fontWeight="bold">
            Order No. {order.order_id}
          </Text>
          <Text fontSize="sm">{order.created_at}</Text>
          <Flex alignItems="center">
            <Text fontWeight="600">Status</Text>
            <Badge ml="1">{order_status_name}</Badge>
          </Flex>
          <Text fontSize="md">Delivered on: {order.delivery_date || deliveryDate}</Text>
          <Flex alignItems="center">
            <Text fontWeight="600">Client</Text>
            <Badge ml="1" variant="outline">
              {order.client_username}
            </Badge>
          </Flex>
        </Box>

        {/* Third Column */}
        <Flex w="40%" justifyContent="space-around">
          {/* First Subcolumn */}
          <Box>
            <Text>{order.total_quantity} items</Text>
            <Text fontWeight="600">R{order.total_amount}</Text>
            <Text fontWeight="300">{order.payment_type_name}</Text>
          </Box>

          {/* Second Subcolumn */}
          <Box w="60%">
            <Flex direction="column">
              {/* First Row */}
              <Flex justifyContent="center" marginBottom="0.2rem">
                <Box w="50%">
                  <Text fontSize="md" fontWeight="500">
                    Status:
                  </Text>
                </Box>
                <Box w="50%">
                  <Select minWidth="150px" value={order_status} onChange={handle__order_status}>
                    {order_statusOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                </Box>
              </Flex>

              {/* Second Row */}
              <Flex justifyContent="space-between" marginBottom="0.2rem">
                <Box w="50%">
                  <Text fontSize="md" fontWeight="500">
                    Payment:
                  </Text>
                </Box>
                <Box w="50%">
                  <Select minWidth="150px" value={payment_status} onChange={handle__payment_status}>
                    {payment_statusOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                </Box>
              </Flex>

              {/* Third Row */}
              <Flex justifyContent="space-between">
                <Box w="50%">
                  <Text fontSize="md" fontWeight="500">
                    Delivery:
                  </Text>
                </Box>
                <Box w="50%">
                  <Input type="date" minWidth="150px" value={deliveryDate || ""} onChange={(e) => handle__delivery_date(e.target.value)} />
                </Box>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </Flex>

      {/* New Row */}
      <Flex alignItems="start" w="86%">
        <Box p="1" borderWidth="1px" minWidth="100%" borderRadius="md" mt="2" flex="1">
          <Progress hasStripe value={progress}  />
        </Box>
      </Flex>
    </Flex>
  );
}

export default AdminOrder;
