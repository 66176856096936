import React, { useState, useEffect } from "react";

function Toast({ message, isSuccess }) {
  const styles = {
    divTheme: {
      position: "fixed",
      zIndex: "1000",
      top: "60px",
      left: "20px",
      padding: "10px",
      borderRadius: "5px",
      backgroundColor: isSuccess ? "green" : "red",
      color: "white",
      animation: "wave 2s infinite",
    },
    waveAnimation: `@keyframes wave {
        0% {
          transform: rotate(0deg);
        }
        50% {
          transform: rotate(10deg);
        }
        100% {
          transform: rotate(0deg);
        }
      }`,
  };
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      // Hide the message after 2 seconds
      setIsVisible(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <style>{styles.waveAnimation}</style>
      {isVisible && <div style={styles.divTheme}>{message}</div>}
    </>
  );
}

export default Toast;
